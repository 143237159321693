@import '../Variables.scss';

.program {
    display: flex;
    justify-content: center;
    background-color: $yle-black;
    cursor: grab;
}

.program-container {
    padding-top: $padding-normal;
    padding-bottom: $padding-normal;
    background-color: $yle-black;
    &__empty {
        padding-left: $padding-normal;
        padding-right: $padding-normal;
        background-color: $yle-black;
        color: $yle-white;
        font-size: $font-size-normal;
        font-family: $yle-font-light;
        text-align: center;
    }
}