@import '../Variables.scss';

.divider {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: $yle-black;
}

.divider-text {
    font-size: $font-size-small;
    font-family: $yle-font-bold;
    color: $yle-white;
}

.line {
    flex-grow: 2;
    height: 0px;
    border: 0.5px solid $yle-white;
    margin-left: $divider-margin;
    margin-right: $divider-margin;
}