$radius: 20px;

// Colors
$yle-turquoise: #3c8996;
$yle-light-gray: #696969;
$yle-dark-gray: #2d2f32;
$yle-white: #ffffff;
$yle-black: #000000;

// Tag
$tag-padding-top-bottom: 5px;
$tag-padding-left-right: 15px;
$tag-margin: 8px / 2;

// Tag container
$tag-container-padding-small: 8px;
$tag-container-padding-medium: 12px;

// Text container
$text-container-padding: 24px;

// General paddings
$padding-small: 8px;
$padding-normal: 16px;
$padding-large: 24px;
$padding-xl: 32px;

// Divider
$divider-margin: 18px;

// General margins
$margin-small: 8px;
$margin-normal: 16px;
$margin-large: 32px;
$margin-xxl: 50px;

// Fonts
$font-size-large: 30px;
$font-size-normal: 20px;
$font-size-small: 15px;

$yle-font-light: "Yle Light";
$yle-font-bold: "Yle Bold";

// Breakpoints from areena-web-sdk repo
$breakpoint-mobile-portrait: "min-width: 320px";
$breakpoint-mobile-landscape: "min-width: 480px";

$breakpoint-tablet: "min-width: 640px";
$breakpoint-tablet-landscape: "min-width: 800px";
$breakpoint-tablet-xl: "min-width: 1024px";

$breakpoint-desktop: "min-width: 1025px";
$breakpoint-desktop-xl: "min-width: 1400px";

@font-face {
  font-family: "Yle Bold";
  src: url(assets/fonts/YLE-BOLD.OTF) format("opentype");
}

@font-face {
  font-family: "Yle Regular";
  src: url(assets/fonts/YLE-REGULAR.OTF) format("opentype");
}

@font-face {
  font-family: "Yle Light";
  src: url(assets/fonts/YLE-LIGHT.OTF) format("opentype");
}
