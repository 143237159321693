@import "../Variables.scss";

.text-container {
  background-color: $yle-black;
  padding: $text-container-padding;
  color: $yle-white;
  font-size: $font-size-normal;
  font-family: $yle-font-light;
}

.text__bolded {
  margin-top: 22px;
  font-family: $yle-font-bold;
  text-decoration: none;
}

.text-link {
  background-color: black;
}

.text-link:visited {
  background-color: $yle-light-gray;
}
