@import '../Variables.scss';

.header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    color: $yle-white;
    font-family: $yle-font-bold;
    height: 92px;
}

.header-text {
    font-size: $font-size-large;
}

.header-small-text {
    margin-left: 6px;
    font-size: 8px;
}