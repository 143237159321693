@import '../Variables.scss';

.tag {
    border-radius: $radius;
    padding: $tag-padding-top-bottom $tag-padding-left-right;
    display: flex;
    margin: $tag-margin;
    border: none;
}

.tag-text {
    color: $yle-white;
    font-size: $font-size-normal;
    font-family: $yle-font-light;
}

.tag__unchecked {
    background-color: $yle-dark-gray;
}

.tag__checked {
    background-color: $yle-turquoise;
}