@import '../Variables.scss';

.button-container {
    padding-top: $padding-small;
    padding-bottom: $padding-xl;
    background-color: $yle-black;
    display: flex;
    justify-content: center;
}

.button {
    border-radius: $radius;
    border-color: $yle-turquoise;
    padding: $tag-padding-top-bottom $tag-padding-left-right;
    display: flex;
    margin: $tag-margin;
    background-color: $yle-black;
}

.button-text {
    color: $yle-white;
    font-size: $font-size-normal;
    font-family: $yle-font-light;
}

.hover {
    background-color: $yle-dark-gray;
}