@import "../Variables.scss";

#tunnelmahaku-player-container {
  z-index: 1500;
  &.floating-player {
    position: sticky;
    bottom: 0;
  }
  .iQFkeY {
    background-color: $yle-white;
    color: $yle-white;
  }
}
