// From _typograhpy.scss
$size14: 0.875em;
$size18: 1.125em;

// From _colors.scss
$ylecyan: #11b4c7;
$darkcyan: #038b98;
$darkercyan: #12747f;
$yleorange: #f0730f;

$black: #000000;
$white: #ffffff;

$darkblue: #114d54;
$darkestbluegrey: #131415;
$darkerbluegrey2: #2d2f32;
$darkerbluegrey3: #1f2224;
$darkerbluegrey1: #3b3f45;
$darkbluegrey: #464c55;
$lightbluegrey: #989ea7;
$lighterbluegrey3: #a9aeb6;
$lighterbluegrey2: #bec2c9;
$lighterbluegrey1: #d8dbdf;
$lightestbluegrey: #eef2f8;

$yleyellow: #ffb400;
$ylelightyellow: #ffecbf;
$yledarkyellow: #ffbe00;
$lastenareenalink: #feb500;

$turquoiseblue: #01545b;
$darkorande: #874a14;
$darkred: #6a0d23;
$lightred: #c22448;
$pinkred: #cf1c36;
$red: #da1F26;

// From _aspect-ratios.scss
@mixin aspect-ratio-16-9 {
    padding-top: 56.25%;
}

@mixin aspect-ratio-square {
    padding-top: 100%;
}

.series-card {
    $card-content-padding: 23px;
    background: no-repeat center center;
    background-size: cover;
    box-sizing: border-box;
    display: block;
    height: 100%;
    position: relative;

    &--normal {
        @include aspect-ratio-square;
    }

    &--wide {
        @include aspect-ratio-16-9;

        & .icon-favorite-off {
            float: right;
            cursor: pointer;
        }

        & .icon-favorite-on {
            float: right;
            color: $pinkred;
            cursor: pointer;
        }

        & .pending {
            cursor: none;
            opacity: 0.2;
        }
    }

    &:hover::after {
        background-color: rgba($black, 0.2);
    }

    &::after {
        background: linear-gradient(to bottom, transparent 33%, $darkerbluegrey3);
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: background 0.2s;
        margin: -1px;
    }

    &__control-container {
        display: block;
        font-size: $size18;
        font-weight: bold;
        margin: 8px;
        position: absolute;
        right: 0;
        text-shadow: 0 0 10px rgba($black, 0.6);
        top: 0;
        z-index: 2;
    }

    &__content {
        bottom: 0;
        display: block;
        left: 0;
        padding: 0 $card-content-padding $card-content-padding;
        position: absolute;
        right: 0;
        z-index: 1;

        &__title {
            color: $white;
            font-size: $size18;
            margin: 8px 0 0;
            text-shadow: 0 0 4px rgba($black, 0.4);
        }

        &__description {
            color: $lighterbluegrey2;
            font-size: $size14;
        }
    }
}
