@import '../Variables.scss';

.tag-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: $yle-black;
    padding-top: $tag-container-padding-medium;
    padding-bottom: $tag-container-padding-medium;
    padding-right: $tag-container-padding-small;
    padding-left: $tag-container-padding-small;
}